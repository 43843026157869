import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useParams
} from "react-router-dom";
import PodcastListFilteredElement from '.././Components/PodcastListFilteredElement';
import PodcastListPrograms from '.././Components/PodcastListPrograms';
import PodcastSingleFiltered from './PodcastSingleFiltered';
import BreadcrumbsPodcasts from '.././Components/BreadcrumbsPodcasts';
// podcasts update 2024
function PodcastListFiltered() {

    let match = useRouteMatch();
    document.title = "Podcasty/Video - Radio Kampus 97,1FM #SAMESZTOSY";
    return (

    <div className="podcasts-section">


        <BreadcrumbsPodcasts />
        <div className="dynamic-boxes">
            <div className="box-1"> </div>
            <div className="box-2"> </div>
            <div className="box-3"> </div>
        </div>
        <div className="content-header with-filter">
            <h2>Podcasty</h2>

            {/* <PodcastListCategories /> */}
        </div>
        <div className="podcast-list-container">

            <Switch>

                <Route path="/podcasty/:podcastCategory/page:podcastCategory?">
                    <PodcastListElementContainer />
                </Route>

                <Route path="/podcasty/sluchaj/:podcastName">
                    <PodcastSingleFiltered />
                </Route>

                <Route path="/podcasty/page:podcastCategory?">
                    <PodcastListElementContainer />
                </Route>

                <Route path={`${match.path}/:podcastCategory`}>
                    <PodcastListElementContainer />
                </Route>

                <Route path="/podcasty">
                    <PodcastListPrograms />
                </Route>

            </Switch>

        </div>
    </div>
    );

}

function PodcastListElementContainer() {

    let { podcastCategory } = useParams();
    return (
        <div><PodcastListFilteredElement path={ podcastCategory } /></div>
    )

}

export default PodcastListFiltered;
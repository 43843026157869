import React, { Component } from 'react'
import {
  Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";
import Header from './Components/Header';
import HeaderPlayer from './Components/HeaderPlayer';
import ScrollButton from './Components/ScrollButton';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';

import ContactPage from './Views/ContactPage';
import Home from './Views/Home';
import NewsList from './Views/NewsList';
import PodcastList from './Views/PodcastList';
import PodcastListFiltered from './Views/PodcastListFiltered';
import ProgramList from './Views/ProgramList';
import People from './Views/People';
import WeekPlaylist from './Views/WeekPlaylist';
import DayPlaylist from './Views/DayPlaylist';
import InfoPage from './Views/InfoPage';
import CustomPage from './Views/CustomPage';
import PlayerInfo from './Views/PlayerInfo';
import SearchPage from './Views/Search';
import AlterlatorPage from './Views/Alterlator';
import SummaryPage from './Views/Summary';
import NotFound from './Views/NotFound';

import CookieConsent from "react-cookie-consent";

import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel';
// podcasts update 2024
const history = createBrowserHistory()
history.listen(location => {
	if (process.env.NODE_ENV !== 'development') {
		ReactGA.set({ page: location.pathname })
		ReactGA.pageview(location.pathname)
		ReactPixel.pageView()
	}
})

export default class AppRoutes extends Component {
	componentDidMount() {
		if (process.env.NODE_ENV !== 'development') {
			ReactGA.pageview(window.location.pathname)
		}
	}

	render() {
		return (

    <Router history={history}>
      <div className="main-container">
        <Header />
        <HeaderPlayer />
        <div className="main-content-container">







         <Switch>

            {/*<Route path="/kontakt" onClick={() => console.log('Entered /')} />*/}

            <Route path="/kontakt">
              <ScrollToTop />
              <ContactPage />
            </Route>

            <Route path="/ramowka">
              <ScrollToTop />
              <WeekPlaylist />
            </Route>

            <Route path="/playlista">
              <ScrollToTop />
              <DayPlaylist />
            </Route>

            <Route path="/podcasty">
              <ScrollToTop />
              <PodcastListFiltered />
            </Route>

            <Route path="/podcasty-video">
              <ScrollToTop />
              <PodcastList />
            </Route>


            <Route path="/programy">
              <ScrollToTop />
              <ProgramList />
            </Route>

            <Route path="/ludzie">
              <ScrollToTop />
              <People />
            </Route>

           <Route path="/aktualnosci">
              <ScrollToTop />
              <NewsList />
            </Route>

            <Route path="/informacje">
              <ScrollToTop />
              <InfoPage />
            </Route>

            <Route path="/akcje">
              <ScrollToTop />
              <CustomPage />
            </Route>

            <Route exact path="/takbrzmi">
              <Redirect to="/akcje/takbrzmi2021" />
            </Route>


            <Route path="/player">
              <ScrollToTop />
              <PlayerInfo />
        	  </Route>

            <Route path="/alterlator">
              <ScrollToTop />
              <AlterlatorPage />
        	  </Route>

            <Route path="/podsumowanie">
              <ScrollToTop />
              <SummaryPage />
        	  </Route>

            <Route path="/szukaj/:searchQuery">
              <ScrollToTop />
              <SearchPage />
            </Route>

            <Route path="/" exact>
              <ScrollToTop />
              <Home />
            </Route>

          <Route path="*" status={404}>
              <ScrollToTop />
              <NotFound />
          </Route>

          </Switch>


        </div>
        <ScrollButton />
      </div>
        <Footer />
<CookieConsent buttonText="Rozumiem" style={{ background: "rgba(0, 0, 0, 0.8)" }} buttonStyle={{ color: "#fff", backgroundColor: "#f43f0a", fontSize: "12px" }}>
<span style={{ fontSize: "12px" }}>Ta strona używa cookies i podobnych technologii.
Brak zmiany ustawienia przeglądarki oznacza akceptację zastosowania tych narzędzi przez stronę radiokampus.fm.</span>
</CookieConsent>
    </Router>




		)
	}
}
import React from "react";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';
// podcasts update 2024
class PodcastSingleFiltered extends React.Component {

  // State will apply to the posts object which is set to loading by default
  state = {
    podcasts: [],
    isLoading: true,
    errors: null
  };


  // Now we're going to make a request for data using axios
  getPosts() {
      // let podcastName = this.props.location;
     let pathString = window.location.pathname;
     const stripTrailingSlash = (str) => {
      return str.endsWith('/') ?
          str.slice(0, -1) :
          str;
  };
     let podcastName = stripTrailingSlash(pathString).split('/');
     podcastName = podcastName[podcastName.length-1];
    //  console.log(podcastName);
    // let lastChar = podcastName.substr(-1); // Selects the last character
    // if (lastChar !== '/') {         // If the last character is not a slash
    //    podcastName = podcastName + '/';            // Append a slash to it.
    // }
    // let podcastName = pathString;

    axios
      // This is where the data is hosted
      .get(`${apiUrl}/podcasty/?api=true&single&q=${podcastName}`, {
    headers: {
        // 'Content-Type': 'application/json'
    },

    })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          podcasts: response.data,
          isLoading: false
        });

      })
      // If we catch any errors connecting, let's update accordingly
            .catch(error => {
                console.log(error);
                    // window.location.href = '/not-found';

                this.setState({ error, isLoading: false } )
            });
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {

    this.getPosts();
  }
  // Putting that data to use
  render() {

    const { isLoading, podcasts } = this.state;
     return (
      <React.Fragment>
        <div className="podcasts-single">
        <div>
          {!isLoading ? (
            podcasts.map(podcast => {

              const { id, title, podcast_image, podcast_description, podcast_source } = podcast;
              document.title = title + " - Radio Kampus 97,1FM #SAMESZTOSY";
              return (

                <div className="podcasts-single-element" key={id}>


                  <div className="podcast-title"> <h2>{title}</h2> <img src={podcast_image} alt="" /></div>
                  <div className="podcast-description">
                  <div dangerouslySetInnerHTML={ { __html: podcast_description }} />
                <div className="podcast-source" dangerouslySetInnerHTML={ { __html: podcast_source }} /></div></div>

              );
            })
          ) : (
            <p>Wczytywanie...</p>
          )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PodcastSingleFiltered;